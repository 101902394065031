import axios from 'axios'
import { CONFIG } from '../config'
import { CONSTANTS } from '../config/constants'

const buildChannelData = (orderReference) => {
    return {
        channel: orderReference?.startsWith('EO_') ? '11' : '03',
        source: orderReference?.startsWith('EO_')
            ? 'CHECKOUT_FORM_EXPRESS_ORDER'
            : 'CHECKOUT_FORM'
    }
}

const authHeaders = (merchant_id, secret) => {
    const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN)
    return {
        Authorization: token,
        secret: secret,
        merchant: merchant_id
    }
}

export const getServiceConfigAPI = (merchant_id) => {
    return new Promise((resolve) => {
        try {
            axios({
                method: 'get',
                url: `${CONFIG.CONFIG_BUCKET}/${merchant_id?.replace(
                    /-/g,
                    ''
                )}.json`,
                headers: {
                    'Access-Control-Allow-Headers': '*',
                    'Content-Type': 'application/json'
                },
                timeout: CONFIG.REQUEST_TIMEOUT
            })
                .then((res) => resolve(res.data))
                .catch(() => resolve(false))
        } catch (error) {
            return resolve(false)
        }
    })
}

export const getMerchantCredentials = async (session, type = 'session') => {
    try {
        const credentials = (
            await axios.post(
                `${CONFIG.FORMS_API_URL}/getCheckoutSessionOrInvoiceData`,
                {
                    [type]: session
                },
                { timeout: CONFIG.REQUEST_TIMEOUT }
            )
        ).data

        return credentials
    } catch (error) {
        throw new Error(error.message)
    }
}

export const generateToken = async (merchant_id, secret) => {
    try {
        const { body } = (
            await axios.post(
                `${CONFIG.MERCHANT_URL}/security/generateToken`,
                {
                    GP_SECRET_KEY: secret,
                    GP_MERCHANT_ID: merchant_id
                },
                { timeout: CONFIG.REQUEST_TIMEOUT }
            )
        ).data
        localStorage.setItem(CONSTANTS.ACCESS_TOKEN, body[0] || '')
        return body[0] || ''
    } catch (error) {
        throw new Error(error.message)
    }
}

export const getMerchantData = async (merchant_id, secret) => {
    try {
        const requestData = {
            merchantID: merchant_id,
            secret
        }

        let res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.FORMS_API_URL}/get_merchant_data`,
                data: requestData,
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data
        if (!res) {
            return res
        }
        return typeof res === 'string' ? JSON.parse(res) : res
    } catch (error) {
        throw new Error(error.message)
    }
}

export const getSessionView = async (session, merchant_id, secret) => {
    try {
        const res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.FORMS_API_URL}/getSessionView`,
                data: { session },
                headers: authHeaders(merchant_id, secret),
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data
        return typeof res === 'string' ? JSON.parse(res) : res
    } catch (error) {
        throw new Error(error.message)
    }
}

export const getInvoiceLinkShortenedData = async (
    shortened,
    merchant_id,
    secret
) => {
    try {
        const res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.FORMS_API_URL}/getInvoiceData`,
                data: { shortened },
                headers: authHeaders(merchant_id, secret),
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data

        const data = typeof res === 'string' ? JSON.parse(res) : res

        if (!data) {
            throw new Error('Invalid link')
        }

        return data
    } catch (error) {
        throw new Error(error.message)
    }
}

export const af_pa_setup = async (cardData, token, orderReference) => {
    try {
        const res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.ORCHESTRATOR_ENDPOINTV2}/3ds/setup`,
                data: {
                    cardData,
                    additional: {
                        channelData: buildChannelData(orderReference)
                    }
                },
                headers: { 'session-token': token },
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data.result
        return typeof res === 'string' ? JSON.parse(res) : res
    } catch (error) {
        throw new Error(error.message)
    }
}

export const af_pa_enrollment = async (
    cardData,
    token,
    referenceId,
    transactionId,
    orderReference
) => {
    try {
        const res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.ORCHESTRATOR_ENDPOINTV2}/3ds/enrollment`,
                data: {
                    cardData,
                    referenceId,
                    transactionId,
                    redirectURL: CONFIG.CHECKOUTFORM_URL,
                    additional: {
                        channelData: buildChannelData(orderReference)
                    }
                },
                headers: { 'session-token': token },
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data.result
        return typeof res === 'string' ? JSON.parse(res) : res
    } catch (error) {
        throw new Error(error.message)
    }
}

export const af_pa_validate = async (
    cardData,
    token,
    transactionId,
    authenticationTransactionId,
    orderReference
) => {
    try {
        const res = (
            await axios({
                method: 'POST',
                url: `${CONFIG.ORCHESTRATOR_ENDPOINTV2}/3ds/validate`,
                data: {
                    cardData,
                    authenticationTransactionId,
                    transactionId,
                    additional: {
                        channelData: buildChannelData(orderReference)
                    }
                },
                headers: { 'session-token': token },
                timeout: CONFIG.REQUEST_TIMEOUT
            })
        ).data.result
        return typeof res === 'string' ? JSON.parse(res) : res
    } catch (error) {
        throw new Error(error.message)
    }
}
